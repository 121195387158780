import 'slick-carousel';

mobileOnlySlider(".js-slider-mobile", false, false, 1200);

function mobileOnlySlider($slidername, $dots, $arrows, $breakpoint) {
    let slider = $($slidername);
    let settings = {
        mobileFirst: true,
        dots: $dots,
        arrows: $arrows,
        responsive: [
            {
                breakpoint: $breakpoint,
                settings: "unslick"
            }
        ]
    };

    slider.slick(settings);

    $(window).on("resize", function () {
        if ($(window).width() > $breakpoint) {
            return;
        }
        if (!slider.hasClass("slick-initialized")) {
            return slider.slick(settings);
        }
    });
}

export function initSlider() {
    $('.js-slider').slick({
        slidesToShow: 6,
        slidesToScroll: 6,
        responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
      ]
    })
}
