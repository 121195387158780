const $filters = $('.js-filters a');
const searchParams = new URLSearchParams(window.location.search);

$filters.on('click', function(e) {
  e.preventDefault();

  const params = searchParams;
  let type = params.get('type');
	if (!type) {
		type = 'inwoner';
	}
  let category = params.get('category');

  const $clickedElement = $(e.currentTarget);

  const newCategory = $clickedElement.data('category');
  const newType = $clickedElement.data('type');

  if (newCategory !== undefined) {
    if (newCategory === category) {
      return;
    }
    category = newCategory;
  } else if (newType !== undefined) {
    if (newType === type) {
      return;
    }
    type = newType;
  } else {
    category = null;
  }

  const baseUrl = `${window.location.protocol}//${window.location.hostname}${window.location.pathname}`;
  let urlWithParam = `${baseUrl}?type=${type}`;

  if (category) {
    urlWithParam += `&category=${category}`;
  }

  window.location.href = urlWithParam;
});
