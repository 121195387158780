import {initSlider} from './js-slider';

$(window).on('load',function(){
    initSlider();

    setTimeout(function(){
        $('.loader').addClass('hide');
    },1500)

    setTimeout(function(){
        $('.loader').remove();
    },1900)
})
