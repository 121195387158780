const { data } = require("jquery");

//js-toggle
$(".js-toggle").on("click", function (e) {
	e.preventDefault();
	$(".header").toggleClass("is-open");
	$(this).toggleClass("is-active");

	$("body").toggleClass("main");
});

$(".js-dropdown .dropdown__head").on("click", function (e) {
	e.preventDefault();
	var dropdown = $(this).parents(".js-dropdown");
	var dropdownBody = dropdown.find(".dropdown__body");

	if (dropdownBody.length > 0) {
		$(".js-dropdown").not(dropdown).removeClass("open");
		dropdown.toggleClass("open");
	}
});

$(".js-tax-types a").on("click", function (e) {
	e.preventDefault();
	$(".js-tax-types li.current").removeClass("current");
	$(this).closest("li").addClass("current");
});

$(".js-tax-categories a").on("click", function (e) {
	e.preventDefault();
	$(".js-tax-categories a.btn--red")
		.removeClass("btn--red")
		.addClass("btn--blue");
	$(this).removeClass("btn--blue").addClass("btn--red");
});

var currentSlug = window.location.pathname;

if (currentSlug.includes("/professional/")) {
	$("#professional").removeClass("btn--pink");
} else {
	$("#citizen").removeClass("btn--pink");
}
